import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PileTurnerListDsService } from '@pt/core/data-services/pile-turner-list-ds';
import { PileTurnerWorkCenterService } from '@pt/core/services';
import { navUrls } from '@pt/shared/utils';
import { RelatedWorkCenter } from 'chronos-core-client';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthorizeService } from 'chronos-shared';

@Injectable({
  providedIn: 'root'
})
export class WorkCenterGuard implements CanActivate {
  private readonly WORK_CENTER_QUERY = 'wcid';

  constructor(
    private pileTurnerListDsService: PileTurnerListDsService,
    private pileTurnerWorkCenterService: PileTurnerWorkCenterService,
    private authorizeService: AuthorizeService,
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    const externalWorkCenterId = route.queryParamMap.get(this.WORK_CENTER_QUERY);

    if (externalWorkCenterId) {
      return this.pileTurnerListDsService.getPileTurners().pipe(
        map((workCenters) => this.getAvailableWorkCenter(workCenters, externalWorkCenterId)),
        map((workCenter) => this.redirectToWorkCenter(workCenter, state))
      );
    } else if (!this.pileTurnerWorkCenterService.getPileTurnerId()) {
      return this.authorizeService.isAuthenticated().pipe(
        switchMap((isAuthenticated) => this.initializePileTurnerId(isAuthenticated)),
        take(1)
      );
    } else {
      return of(true);
    }
  }

  private getAvailableWorkCenter(workCenters: RelatedWorkCenter[], externalId: string): RelatedWorkCenter | undefined {
    return workCenters.find((workCenter) => workCenter.externalWorkCenterId === externalId);
  }

  private redirectToWorkCenter(workCenter: RelatedWorkCenter | undefined, state: RouterStateSnapshot): UrlTree {
    if (workCenter) {
      const path = state.url.split('?')[0];
      this.pileTurnerWorkCenterService.setPileTurnerId(workCenter.id);
      return this.router.createUrlTree([path]);
    } else {
      return this.router.createUrlTree([navUrls.pileTurnerSelection]);
    }
  }

  private initializePileTurnerId(isAuthenticated: boolean): Observable<UrlTree | boolean> {
    if (isAuthenticated) {
      return this.pileTurnerWorkCenterService
        .initializePileTurnerId()
        .pipe(
          switchMap((isInitialized) =>
            isInitialized ? of(this.router.createUrlTree([navUrls.root])) : of(this.router.createUrlTree([navUrls.pileTurnerSelection]))
          )
        );
    } else {
      return of(true);
    }
  }
}

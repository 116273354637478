<section class="pallet-list-modal chronos-dialog--size-lg">
  <div class="pallet-list-modal__dummy-filter">
    <p-checkbox
      [label]="'MOUNTING.SHOW_ONLY_DUMMY' | translate"
      [value]="dummyFilter$ | async"
      (onChange)="onDummyFilterChange($event.checked)"
    ></p-checkbox>
  </div>
  <lib-container-list-template>
    <lib-container-list-columns [gridColumnsWidths]="'100px 120px 120px 70px 70px 150px auto'">
      <lib-order-by-button [order]="palletSequence$ | async" (orderChanged)="onPalletOrderChange($event)">
        {{ 'MOUNTING.SEQUENCE_NUMBER' | translate }}
      </lib-order-by-button>
      <div>{{ 'MOUNTING.ARTICLE' | translate }}</div>
      <div>{{ 'MOUNTING.SSCC' | translate }}</div>
      <div>{{ 'MOUNTING.CONSUMED' | translate }}</div>
      <div>{{ 'MOUNTING.MOUNTED' | translate }}</div>
      <div>{{ 'MOUNTING.WHITE_WASTE' | translate }}</div>
    </lib-container-list-columns>

    <div class="pallet-list">
      <div class="pallet-list__by-order" *ngIf="!noPalletsToggle; else noPallets">
        <pt-pallet-list
          [article]="previousArticle$ | async"
          [palletList]="previousBlockPallets$ | async"
          [productionOrderInfo]="previousProductionOrderInfo$ | async"
          (replaceDummyClicked)="onReplaceClick($event)"
        ></pt-pallet-list>
        <pt-pallet-list
          [article]="activeArticle"
          [palletList]="activeBlockPallets$ | async"
          [productionOrderInfo]="activeProductionOrderInfo$ | async"
          [isActiveOrder]="true"
          (whiteWasteAdded)="addPalletWhiteWaste($event)"
          (whiteWasteReset)="resetPalletWhiteWaste($event)"
          (dismountClicked)="onDismountClick($event)"
          (replaceDummyClicked)="onReplaceClick($event)"
        ></pt-pallet-list>
      </div>
      <ng-template #noPallets>
        <lib-container-list-item-empty></lib-container-list-item-empty>
      </ng-template>
    </div>
  </lib-container-list-template>

  <lib-modal-footer [showDeclineButton]="false" [submitLabel]="'MODAL_FOOTER.CLOSE'" (submitClicked)="closeModal()"></lib-modal-footer>
</section>

import { Injectable } from '@angular/core';
import { apiUrls } from '@pt/shared/utils';
import { ApiAuthorizationConfig, ChronosSharedConfiguration } from 'chronos-shared';
import { WebSocketClientService } from 'chronos-core-client';
import { ApmService } from '@elastic/apm-rum-angular';

interface AuthConfig {
  authority?: string;
  clientId?: string;
  scope?: string;
  responseType?: string;
}

interface FrontendConfig {
  apm: any;
  pandaRootUrl?: string;
  authentication?: AuthConfig;
}

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  constructor(
    private chronosSharedConfiguration: ChronosSharedConfiguration,
    private authConfig: ApiAuthorizationConfig,
    private webSocketClientService: WebSocketClientService,
    private apmService: ApmService
  ) {}

  public async Init(): Promise<any> {
    // deliberately avoid using Angular HTTP here to not trigger any implicit default configurations
    const frontendConfigResponse = await fetch(apiUrls.getFrontendConfig());
    const frontendConfig: FrontendConfig = await frontendConfigResponse.json();

    // initialize APM first to ensure all errors are properly reported
    this.initializeApm(frontendConfig.apm);

    if (frontendConfig.authentication) {
      this.initializeAuthentication(frontendConfig.authentication);
    }

    this.initializeUrls(frontendConfig);

    await this.webSocketClientService.startConnection();
  }

  private initializeApm(apmConfig: any) {
    const defaults = {
      enabled: false,
      serviceName: 'chronos-live',
      logLevel: 'warn'
    };
    const config = { ...defaults, ...apmConfig };

    if (config.enabled) {
      this.apmService.init(config);
    }
  }

  private initializeUrls(frontendConfig: FrontendConfig) {
    if (frontendConfig.pandaRootUrl) {
      this.chronosSharedConfiguration.pandaRootUrl = frontendConfig.pandaRootUrl.replace(/\/$/, '');
    }
  }

  private initializeAuthentication(fetchedAuthConfig: AuthConfig) {
    this.authConfig.authority = fetchedAuthConfig.authority ?? this.authConfig.authority;
    this.authConfig.clientId = fetchedAuthConfig.clientId ?? this.authConfig.clientId;
    this.authConfig.responseType = fetchedAuthConfig.responseType ?? this.authConfig.responseType;
    this.authConfig.scope = fetchedAuthConfig.scope ?? this.authConfig.scope;
  }
}
